import { IVA_TYPE_10_PERCENT } from "./iva";

export interface AutoInvoiceItem {
  id?: number;
  invoiceId?: number;
  description?: string;
  obs?: string;
  measureUnit?: number;
  code?: string;
  unitAmount?: number;
  quantity?: number;
  totalAmount?: number;
  discount?: number;
}

export interface AutoInvoice {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  uniqueCode?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  providerType?: string;
  providerDocumentType?: string;
  providerDocumentNumber?: string;
  providerFullName?: string;
  providerAddress?: string;
  providerHouseNumber?: number;
  providerDepartment?: number;
  providerDepartmentDescription?: string;
  providerDistrict?: number;
  providerDistrictDescription?: string;
  providerCity?: number;
  providerCityDescription?: string;
  transactionAddress?: string;
  transactionHouseNumber?: number;
  transactionDepartment?: number;
  transactionDepartmentDescription?: string;
  transactionDistrict?: number;
  transactionDistrictDescription?: string;
  transactionCity?: number;
  transactionCityDescription?: string;
  autoInvoiceDate?: string;
  currency?: string;
  pygExchangeRate?: number;
  certificateNumber?: string;
  certificateCode?: string;
  description?: string;
  obs?: string;
  amount?: number;
  totalDiscount?: number;
  amountAfterDiscount?: number;
  term?: string;
  globalDiscount?: number;

  autoInvoiceNumber?: string;
  cdc?: string;

  xmlPath?: string;
  pdfPath?: string;

  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;

  items: AutoInvoiceItem[];

  emailSent?: boolean;

  createdAt?: string;
  updatedAt?: string;
}

export const AUTO_INVOICE_SALE_TYPE_CASH = "cash";
export const AUTO_INVOICE_SALE_TYPE_CASH_LABEL = "Contado";
export const AUTO_INVOICE_SALE_TYPE_CREDIT = "credit";
export const AUTO_INVOICE_SALE_TYPE_CREDIT_LABEL = "Crédito";

export const AUTO_INVOICE_STATUS_PENDING = "pending";
export const AUTO_INVOICE_STATUS_PENDING_LABEL = "Pendiente";
export const AUTO_INVOICE_STATUS_ERROR = "error";
export const AUTO_INVOICE_STATUS_ERROR_LABEL = "Error";
export const AUTO_INVOICE_STATUS_GENERATED = "generated";
export const AUTO_INVOICE_STATUS_GENERATED_LABEL = "Generado";
export const AUTO_INVOICE_STATUS_UPLOADED_TO_SET = "uploaded_to_set";
export const AUTO_INVOICE_STATUS_UPLOADED_TO_SET_LABEL = "Subido";
export const AUTO_INVOICE_STATUS_APPROVED_BY_SET = "approved_by_set";
export const AUTO_INVOICE_STATUS_APPROVED_BY_SET_LABEL = "Aprobado";
export const AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR = "set_unknown_error";
export const AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR_LABEL = "Desconocido";
export const AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED =
  "cancellation_requested";
export const AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const AUTO_INVOICE_STATUS_CANCELLED = "cancelled";
export const AUTO_INVOICE_STATUS_CANCELLED_LABEL = "Anulado";
export const AUTO_INVOICE_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const AUTO_INVOICE_STATUS_CANCELLATION_FAILED_LABEL =
  "Anulación Fallida";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_REQUESTED =
  "disagreement_requested";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_REQUESTED_LABEL =
  "Disconformidad Solicitada";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_ACCEPTED =
  "disagreement_accepted";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_ACCEPTED_LABEL =
  "Disconformidad Aceptada";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_FAILED = "disagreement_failed";
export const AUTO_INVOICE_STATUS_DISAGREEMENT_FAILED_LABEL =
  "Disconformidad Fallida";
export const AUTO_INVOICE_STATUS_RESEND_REQUESTED = "resend_requested";
export const AUTO_INVOICE_STATUS_RESEND_REQUESTED_LABEL = "Reenviar";

export const AUTO_INVOICE_STATUS_CHOICES: Map<string, string> = new Map([
  [AUTO_INVOICE_STATUS_PENDING, AUTO_INVOICE_STATUS_PENDING_LABEL],
  [AUTO_INVOICE_STATUS_ERROR, AUTO_INVOICE_STATUS_ERROR_LABEL],
  [AUTO_INVOICE_STATUS_GENERATED, AUTO_INVOICE_STATUS_GENERATED_LABEL],
  [
    AUTO_INVOICE_STATUS_UPLOADED_TO_SET,
    AUTO_INVOICE_STATUS_UPLOADED_TO_SET_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_APPROVED_BY_SET,
    AUTO_INVOICE_STATUS_APPROVED_BY_SET_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR,
    AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED,
    AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [AUTO_INVOICE_STATUS_CANCELLED, AUTO_INVOICE_STATUS_CANCELLED_LABEL],
  [
    AUTO_INVOICE_STATUS_CANCELLATION_FAILED,
    AUTO_INVOICE_STATUS_CANCELLATION_FAILED_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_DISAGREEMENT_REQUESTED,
    AUTO_INVOICE_STATUS_DISAGREEMENT_REQUESTED_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_DISAGREEMENT_ACCEPTED,
    AUTO_INVOICE_STATUS_DISAGREEMENT_ACCEPTED_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_DISAGREEMENT_FAILED,
    AUTO_INVOICE_STATUS_DISAGREEMENT_FAILED_LABEL,
  ],
  [
    AUTO_INVOICE_STATUS_RESEND_REQUESTED,
    AUTO_INVOICE_STATUS_RESEND_REQUESTED_LABEL,
  ],
]);

export const AUTO_INVOICE_PROVIDER_TYPE_NO_RUC = "no_ruc";
export const AUTO_INVOICE_PROVIDER_TYPE_NO_RUC_LABEL = "No Contribuyente";
export const AUTO_INVOICE_PROVIDER_TYPE_FOREIGN = "foreign";
export const AUTO_INVOICE_PROVIDER_TYPE_FOREIGN_LABEL = "Extranjero";

export const AUTO_INVOICE_PROVIDER_TYPE_CHOICES: Map<string, string> = new Map([
  [AUTO_INVOICE_PROVIDER_TYPE_NO_RUC, AUTO_INVOICE_PROVIDER_TYPE_NO_RUC_LABEL],
  [
    AUTO_INVOICE_PROVIDER_TYPE_FOREIGN,
    AUTO_INVOICE_PROVIDER_TYPE_FOREIGN_LABEL,
  ],
]);

export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI =
  "paraguayan_dni";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI_LABEL =
  "Cédula paraguaya";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PASSPORT = "passport";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PASSPORT_LABEL = "Pasaporte";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_FOREIGN_DNI = "foreign_dni";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_FOREIGN_DNI_LABEL =
  "Cédula Extranjera";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_RESIDENCE_CARD =
  "residence_card";
export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_RESIDENCE_CARD_LABEL =
  "Carnet de residencia";

export const AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_CHOICES: Map<string, string> =
  new Map([
    [
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI,
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI_LABEL,
    ],
    [
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PASSPORT,
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PASSPORT_LABEL,
    ],
    [
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_FOREIGN_DNI,
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_FOREIGN_DNI_LABEL,
    ],
    [
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_RESIDENCE_CARD,
      AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_RESIDENCE_CARD_LABEL,
    ],
  ]);

export const newAutoInvoice = (taxPayerId?: number): AutoInvoice => {
  return {
    taxPayerId: taxPayerId,
    items: [],
  };
};

export const newAutoInvoiceItem = (): AutoInvoiceItem => {
  return {};
};

export const formatAutoInvoiceNumber = (autoInvoice: AutoInvoice): string => {
  return `${autoInvoice.establishmentCode}-${autoInvoice.dispatchPointIdentifier}-${autoInvoice.autoInvoiceNumber}`;
};
