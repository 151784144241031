import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import userReducer from "./redux/reducers/user";
import DashboardReducer from "./redux/reducers/dashboard";
import taxPayerReducer from "./redux/tax-payer/tax-payer-slice";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: DashboardReducer,
  taxPayer: taxPayerReducer,
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
