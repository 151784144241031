import Establishment from "./establishment";

interface DispatchPoint {
  id?: number;
  identifier?: string;
  establishmentId?: number;
  taxPayerId?: number;
  establishment?: Establishment;
  defaultEconomicActivityId?: number;
  defaultEconomicActivityCode?: string;
  defaultEconomicActivityDescription?: string;
}

export const newDispatchPoint = (): DispatchPoint => {
  return {};
};

export default DispatchPoint;
