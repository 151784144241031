import { IVA_TYPE_10_PERCENT } from "./iva";

export interface DebitNoteItem {
  id?: number;
  debitNoteId?: number;
  description?: string;
  obs?: string;
  code?: string;
  measureUnit?: number;
  unitAmount?: number;
  quantity?: number;
  totalAmount?: number;
  ivaType?: string;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  ivaBase?: number;
  iva10Percent?: number;
  iva5Percent?: number;
}

export interface DebitNote {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  uniqueCode?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  clientId?: number;
  clientCode?: string;
  clientIsTaxPayer?: boolean;
  clientRuc?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  clientType?: string;
  clientCountryCode?: string;
  clientHasAddress?: boolean;
  clientAddress?: string;
  clientHouseNumber?: number;
  clientDepartment?: number;
  clientDepartmentDescription?: string;
  clientDistrict?: number;
  clientDistrictDescription?: string;
  clientCity?: number;
  clientCityDescription?: string;
  clientPhoneNumber?: string;
  clientEmail?: string;
  debitNoteDate?: string;
  debitNoteNumber?: string;
  currency?: string;
  pygExchangeRate?: number;
  description?: string;
  obs?: string;
  amount?: number;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  iva10Percent?: number;
  iva5Percent?: number;

  associatedDocumentType?: string;
  invoiceId?: number;
  associatedDocumentCdc?: string;
  associatedDocumentPrintedType?: string;
  associatedDocumentStampIdentifier?: string;
  associatedDocumentEstablishmentCode?: string;
  associatedDocumentDispatchPointCode?: string;
  associatedDocumentNumber?: string;
  associatedDocumentDate?: string;

  invoiceNumber?: string;
  cdc?: string;

  xmlPath?: string;
  pdfPath?: string;

  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;

  items: DebitNoteItem[];

  createdAt?: string;
  updatedAt?: string;
}

export const DEBIT_NOTE_STATUS_PENDING = "pending";
export const DEBIT_NOTE_STATUS_PENDING_LABEL = "Pendiente";
export const DEBIT_NOTE_STATUS_ERROR = "error";
export const DEBIT_NOTE_STATUS_ERROR_LABEL = "Error";
export const DEBIT_NOTE_STATUS_GENERATED = "generated";
export const DEBIT_NOTE_STATUS_GENERATED_LABEL = "Generado";
export const DEBIT_NOTE_STATUS_UPLOADED_TO_SET = "uploaded_to_set";
export const DEBIT_NOTE_STATUS_UPLOADED_TO_SET_LABEL = "Subido";
export const DEBIT_NOTE_STATUS_APPROVED_BY_SET = "approved_by_set";
export const DEBIT_NOTE_STATUS_APPROVED_BY_SET_LABEL = "Aprobado";
export const DEBIT_NOTE_STATUS_SET_UNKNOWN_ERROR = "set_unknown_error";
export const DEBIT_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL = "Desconocido";
export const DEBIT_NOTE_STATUS_CANCELLATION_REQUESTED =
  "cancellation_requested";
export const DEBIT_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const DEBIT_NOTE_STATUS_CANCELLED = "cancelled";
export const DEBIT_NOTE_STATUS_CANCELLED_LABEL = "Anulado";
export const DEBIT_NOTE_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const DEBIT_NOTE_STATUS_CANCELLATION_FAILED_LABEL = "Anulación Fallida";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_REQUESTED =
  "disagreement_requested";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL =
  "Disconformidad Solicitada";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED = "disagreement_accepted";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL =
  "Disconformidad Aceptada";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_FAILED = "disagreement_failed";
export const DEBIT_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL =
  "Disconformidad Fallida";

export const DEBIT_NOTE_STATUS_CHOICES: Map<string, string> = new Map([
  [DEBIT_NOTE_STATUS_PENDING, DEBIT_NOTE_STATUS_PENDING_LABEL],
  [DEBIT_NOTE_STATUS_ERROR, DEBIT_NOTE_STATUS_ERROR_LABEL],
  [DEBIT_NOTE_STATUS_GENERATED, DEBIT_NOTE_STATUS_GENERATED_LABEL],
  [DEBIT_NOTE_STATUS_UPLOADED_TO_SET, DEBIT_NOTE_STATUS_UPLOADED_TO_SET_LABEL],
  [DEBIT_NOTE_STATUS_APPROVED_BY_SET, DEBIT_NOTE_STATUS_APPROVED_BY_SET_LABEL],
  [
    DEBIT_NOTE_STATUS_SET_UNKNOWN_ERROR,
    DEBIT_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL,
  ],
  [
    DEBIT_NOTE_STATUS_CANCELLATION_REQUESTED,
    DEBIT_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [DEBIT_NOTE_STATUS_CANCELLED, DEBIT_NOTE_STATUS_CANCELLED_LABEL],
  [
    DEBIT_NOTE_STATUS_CANCELLATION_FAILED,
    DEBIT_NOTE_STATUS_CANCELLATION_FAILED_LABEL,
  ],
  [
    DEBIT_NOTE_STATUS_DISAGREEMENT_REQUESTED,
    DEBIT_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL,
  ],
  [
    DEBIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED,
    DEBIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL,
  ],
  [
    DEBIT_NOTE_STATUS_DISAGREEMENT_FAILED,
    DEBIT_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL,
  ],
]);

export const newDebitNote = (taxPayerId?: number): DebitNote => {
  return {
    taxPayerId: taxPayerId,
    items: [],
  };
};

export const newDebitNoteItem = (): DebitNoteItem => {
  return {
    ivaType: IVA_TYPE_10_PERCENT,
  };
};

export const formatDebitNoteNumber = (debitNote: DebitNote): string => {
  return `${debitNote.establishmentCode}-${debitNote.dispatchPointIdentifier}-${debitNote.debitNoteNumber}`;
};
