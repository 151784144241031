import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CInputGroup,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ItemRequestStatus, updateItem } from "../../api/generics";
import User, { newUser } from "../../models/user";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";

interface UserFormProps {
  initialUser?: User;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const UserChangePasswordForm: React.FC<UserFormProps> = ({
  initialUser,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingUser, setEditingUser] = useState<User>(
    initialUser ? initialUser : newUser()
  );
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);

  const onPassword1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password1: e.target.value,
    });
  };

  const onPassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password2: e.target.value,
    });
  };

  const onSave = async () => {
    setSubmitting(true);
    let requestPromise: Promise<ItemRequestStatus<User>>;
    if (editingUser.id === 0) {
      requestPromise = updateItem<User>(
        `/users/${editingUser.id}/password/`,
        editingUser
      );
    } else {
      requestPromise = updateItem<User>(
        `/users/${editingUser.id}/password/`,
        editingUser
      );
    }

    const userStatus = await requestPromise;

    if (userStatus.status !== SUCCESS) {
      if (userStatus.errors !== undefined) {
        setErrors(userStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (userStatus.detail !== undefined) {
        message = userStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const onPassword1ShowClick = () => {
    setShowPassword1(!showPassword1);
  };

  const onPassword2ShowClick = () => {
    setShowPassword2(!showPassword2);
  };

  const clearForm = () => {
    setEditingUser(initialUser ? initialUser : newUser());
  };

  useEffect(() => {
    setEditingUser(initialUser ? initialUser : newUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nueva Contraseña</CLabel>
            </CCol>
            <CCol>
              <CInputGroup>
                <CInput
                  type={showPassword1 ? "text" : "password"}
                  value={emptyValueOnUndefined(editingUser.password1)}
                  onChange={onPassword1Change}
                  placeholder="Ej: Contraseña"
                ></CInput>
                <CButton
                  type="button"
                  color="primary"
                  variant="outline"
                  onClick={onPassword1ShowClick}
                >
                  {showPassword1 ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </CButton>
              </CInputGroup>
              <FieldErrors
                errors={getFieldErrors("password1", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Repita la Contraseña</CLabel>
            </CCol>
            <CCol>
              <CInputGroup>
                <CInput
                  type={showPassword2 ? "text" : "password"}
                  value={emptyValueOnUndefined(editingUser.password2)}
                  onChange={onPassword2Change}
                  placeholder="Ej: Repita la Contraseña"
                ></CInput>
                <CButton
                  type="button"
                  color="primary"
                  variant="outline"
                  onClick={onPassword2ShowClick}
                >
                  {showPassword2 ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </CButton>
              </CInputGroup>
              <FieldErrors
                errors={getFieldErrors("password2", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default UserChangePasswordForm;
