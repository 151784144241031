import { useCallback, useEffect } from "react";
import { getItem, getList } from "../../api/generics";
import TaxPayer from "../../models/tax-payer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  setTaxPayer,
  setTaxPayerErrorLoading,
  setTaxPayerLoading,
  setUserTaxPayers,
} from "../../redux/tax-payer/tax-payer-slice";
import { SUCCESS } from "../../utils/constants/tags";
import Loading from "../indicators/Loading";
import Error from "../error/Error";

const TAX_PAYER_LIMIT = 1000;

interface AppDataLoadedFirstProps {
  children: React.ReactNode;
}

const AppDataLoadedFirst: React.FC<AppDataLoadedFirstProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const taxPayerLoaded = useSelector(
    (state: RootState) => state.taxPayer.loaded
  );

  const taxPayerLoading = useSelector(
    (state: RootState) => state.taxPayer.loading
  );

  const taxPayerError = useSelector((state: RootState) => state.taxPayer.error);

  const loadData = useCallback(async () => {
    if (taxPayerLoading || taxPayerLoaded) {
      return;
    }

    dispatch(setTaxPayerLoading(true));

    const taxPayerRequestStatus = await getItem<TaxPayer>(
      "/tax_payers/default_for_user/"
    );

    if (taxPayerRequestStatus.status !== SUCCESS) {
      dispatch(setTaxPayerErrorLoading(true));
      return;
    }

    const userTaxPayersRequestStatus = await getList<TaxPayer>(
      "/tax_payers/for_user/",
      TAX_PAYER_LIMIT,
      0
    );

    if (
      userTaxPayersRequestStatus.status !== SUCCESS ||
      userTaxPayersRequestStatus.data === undefined
    ) {
      dispatch(setTaxPayerErrorLoading(true));
      return;
    }

    dispatch(setTaxPayer(taxPayerRequestStatus.data!));
    dispatch(setUserTaxPayers(userTaxPayersRequestStatus.data.items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayerLoading, taxPayerLoaded]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!taxPayerLoaded) {
    return <Loading />;
  }

  if (taxPayerError) {
    return <Error />;
  }

  return <>{children}</>;
};

export default AppDataLoadedFirst;
