import DashboardPage from "./dashboard-pages/DashboardPage";
import EconomicActivitesPage from "./dashboard-pages/EconomicActivitiesPage";
import RegimeTypesPage from "./dashboard-pages/RegimeTypesPage";
import TaxPayerPage from "./dashboard-pages/TaxPayerPage";
import UserCrudPage from "./dashboard-pages/UserCrudPage";
import StampsPage from "./dashboard-pages/StampPage";
import EstablishmentPage from "./dashboard-pages/EstablishmentPage";
import InvoicesPage from "./dashboard-pages/InvoicesPage";
import InvoicePage from "./dashboard-pages/InvoicePage";
import ClientsPage from "./dashboard-pages/ClientsPage";
import DispatchPointPage from "./dashboard-pages/DispatchPointPage";
import CreditNotesPage from "./dashboard-pages/CreditNotesPage";
import CreditNotePage from "./dashboard-pages/CreditNotePage";
import DisablementPage from "./dashboard-pages/DisablementPage";
import DebitNotesPage from "./dashboard-pages/DebitNotesPage";
import DebitNotePage from "./dashboard-pages/DebitNotePage";
import RemissionNotesPage from "./dashboard-pages/RemissionNotesPage";
import RemissionNotePage from "./dashboard-pages/RemissionNotePage";
import ReceiptsPage from "./dashboard-pages/ReceiptsPage";
import ReceiptPage from "./dashboard-pages/ReceiptPage";
import AutoInvoicesPage from "./dashboard-pages/AutoInvoicesPage";
import AutoInvoicePage from "./dashboard-pages/AutoInvoicePage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
  },
  taxPayer: {
    path: "/tax_payer/",
    name: "Contribuyente",
    exact: true,
    component: TaxPayerPage,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserCrudPage,
  },
  regimeTypes: {
    path: "/regime_types/",
    name: "Régimenes",
    exact: true,
    component: RegimeTypesPage,
  },
  economicActivities: {
    path: "/economic_activities/",
    name: "Actividades Económicas",
    exact: true,
    component: EconomicActivitesPage,
  },
  stamps: {
    path: "/stamps/",
    name: "Timbrados",
    exact: true,
    component: StampsPage,
  },
  dispatchPoints: {
    path: "/dispatch_points/",
    name: "Puntos de expedición",
    exact: true,
    component: DispatchPointPage,
  },
  establishments: {
    path: "/establishments/",
    name: "Establecimientos",
    exact: true,
    component: EstablishmentPage,
  },
  clients: {
    path: "/clients/",
    name: "Clientes",
    exact: true,
    component: ClientsPage,
  },
  invoices: {
    path: "/invoices/",
    name: "Facturas",
    exact: true,
    component: InvoicesPage,
  },
  addInvoice: {
    path: "/invoice/",
    name: "Agregar Factura",
    exact: true,
    component: InvoicePage,
  },
  autoInvoices: {
    path: "/auto_invoices/",
    name: "Autofacturas",
    exact: true,
    component: AutoInvoicesPage,
  },
  addAutoInvoice: {
    path: "/auto_invoice/",
    name: "Agregar Autofactura",
    exact: true,
    component: AutoInvoicePage,
  },
  creditNotes: {
    path: "/credit_notes/",
    name: "Notas de Crédito",
    exact: true,
    component: CreditNotesPage,
  },
  addCreditNote: {
    path: "/credit_note/",
    name: "Agregar Nota de Crédito",
    exact: true,
    component: CreditNotePage,
  },
  debitNotes: {
    path: "/debit_notes/",
    name: "Notas de Débito",
    exact: true,
    component: DebitNotesPage,
  },
  addDebitNote: {
    path: "/debit_note/",
    name: "Agregar Nota de Débito",
    exact: true,
    component: DebitNotePage,
  },
  remissionNotes: {
    path: "/remission_notes/",
    name: "Notas de Remisión",
    exact: true,
    component: RemissionNotesPage,
  },
  addRemissionNote: {
    path: "/remission_note/",
    name: "Agregar Nota de Remisión",
    exact: true,
    component: RemissionNotePage,
  },
  receipts: {
    path: "/receipts/",
    name: "Recibos",
    exact: true,
    component: ReceiptsPage,
  },
  addReceipt: {
    path: "/receipt/",
    name: "Agregar Recibo",
    exact: true,
    component: ReceiptPage,
  },
  disablements: {
    path: "/disablements/",
    name: "Inutilizaciones",
    exact: true,
    component: DisablementPage,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
