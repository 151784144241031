import Stamp from "../models/stamp";
import { SUCCESS } from "../utils/constants/tags";
import { getList } from "./generics";

const getStampById = async (id?: number): Promise<Stamp | null> => {
  if (id === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  params.set("id", id.toString());

  const requestStatus = await getList<Stamp>("/stamps/", 1, 0, params);

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

export { getStampById };
