import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import Layout from "./components/containers/Layout";
import LoginRequiredRoute from "./routes/LoginRequiredRoute";
import Login from "./pages/Login";
import routes from "./routes";
import AppDataLoadedFirst from "./components/middleware/AppDataLoadedFirst";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function AppRouter() {
  let routeComponents: React.ReactElement[] = [];
  for (const key in routes) {
    const route = routes[key];

    routeComponents.push(
      <LoginRequiredRoute
        key={key}
        path={route.path}
        exact={route.exact}
        render={(props: RouteComponentProps) => (
          <AppDataLoadedFirst>
            <Layout {...props} />
          </AppDataLoadedFirst>
        )}
      />
    );
  }

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/login" component={Login} />
          {routeComponents}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default AppRouter;
