import { CButton, CButtonGroup, CTooltip } from "@coreui/react";
import { RECEIPT_STATUS_GENERATED, Receipt } from "../../models/receipt";

interface ReceiptCrudAtionsProps {
  receipt: Receipt;
  onCancellationClick: (_: Receipt) => void;
  onUpdateClick: (_: Receipt) => void;
  onDeleteClick: (_: Receipt) => void;
}

const ReceiptCrudActions: React.FC<ReceiptCrudAtionsProps> = ({
  receipt,
  onCancellationClick,
  onUpdateClick,
  onDeleteClick,
}) => {
  const cancelButton = (
    <CTooltip content="Anular">
      <CButton
        className="text-white"
        color="secondary"
        onClick={() => {
          onCancellationClick(receipt);
        }}
      >
        <i className="fa fa-ban"></i>
      </CButton>
    </CTooltip>
  );

  const editButton = (
    <CTooltip content="Editar">
      <CButton
        className="text-white"
        color="warning"
        onClick={() => {
          onUpdateClick(receipt);
        }}
      >
        <i className="fa fa-pencil"></i>
      </CButton>
    </CTooltip>
  );

  const deleteButton = (
    <CTooltip content="Eliminar">
      <CButton
        className="text-white"
        color="danger"
        onClick={() => {
          onDeleteClick(receipt);
        }}
      >
        <i className="fa fa-trash"></i>
      </CButton>
    </CTooltip>
  );

  const shouldShowCancelButton = receipt.status === RECEIPT_STATUS_GENERATED;

  const shouldShowEditAndDeleteButton = true;

  return (
    <td className="text-center">
      <CButtonGroup>
        {shouldShowCancelButton ? cancelButton : <></>}
        {shouldShowEditAndDeleteButton ? (
          <>
            {editButton}
            {deleteButton}
          </>
        ) : (
          <></>
        )}
      </CButtonGroup>
    </td>
  );
};

export default ReceiptCrudActions;
