import { CButton, CButtonGroup, CTooltip } from "@coreui/react";
import {
  AUTO_INVOICE_STATUS_APPROVED_BY_SET,
  AUTO_INVOICE_STATUS_ERROR,
  AUTO_INVOICE_STATUS_PENDING,
  AUTO_INVOICE_STATUS_RESEND_REQUESTED,
  AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR,
  AUTO_INVOICE_STATUS_UPLOADED_TO_SET,
  AutoInvoice,
} from "../../models/auto-invoice";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";

interface InvoiceCrudAtionsProps {
  autoInvoice: AutoInvoice;
  onCancellationClick: (_: AutoInvoice) => void;
  onUpdateClick: (_: AutoInvoice) => void;
  onDeleteClick: (_: AutoInvoice) => void;
  onConsultByCdcClick: (_: AutoInvoice) => void;
  onResendClick: (_: AutoInvoice) => void;
}

const AutoInvoiceCrudActions: React.FC<InvoiceCrudAtionsProps> = ({
  autoInvoice,
  onCancellationClick,
  onUpdateClick,
  onDeleteClick,
  onConsultByCdcClick,
  onResendClick,
}) => {
  const [currentAutoInvoice, setCurrentAutoInvoice] = useState(autoInvoice);

  useEffect(() => {
    setCurrentAutoInvoice(autoInvoice);
  }, [autoInvoice]);

  useSub(`auto_invoice_${autoInvoice.id}`, (autoInvoice: AutoInvoice) => {
    setCurrentAutoInvoice(autoInvoice);
  });

  const cancelButton = (
    <CTooltip content="Anular">
      <CButton
        className="text-white"
        color="secondary"
        onClick={() => {
          onCancellationClick(currentAutoInvoice);
        }}
      >
        <i className="fa fa-ban"></i>
      </CButton>
    </CTooltip>
  );

  const editButton = (
    <CTooltip content="Editar">
      <CButton
        className="text-white"
        color="warning"
        onClick={() => {
          onUpdateClick(currentAutoInvoice);
        }}
      >
        <i className="fa fa-pencil"></i>
      </CButton>
    </CTooltip>
  );

  const deleteButton = (
    <CTooltip content="Eliminar">
      <CButton
        className="text-white"
        color="danger"
        onClick={() => {
          onDeleteClick(currentAutoInvoice);
        }}
      >
        <i className="fa fa-trash"></i>
      </CButton>
    </CTooltip>
  );

  const invoiceDateTime = new Date(currentAutoInvoice.createdAt!).getTime();
  const now = new Date().getTime();

  const invoiceOlderThan2Days = invoiceDateTime < now - 2 * 24 * 60 * 60 * 1000;

  const shouldShowCancelButton =
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_APPROVED_BY_SET &&
    !invoiceOlderThan2Days;

  const shouldShowEditAndDeleteButton =
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_PENDING ||
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_ERROR ||
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR;

  const consultByCdcButton = (
    <CTooltip content="Consultar por CDC">
      <CButton
        className="text-white"
        color="primary"
        onClick={() => {
          onConsultByCdcClick(autoInvoice);
        }}
      >
        <i className="fa fa-question-circle"></i>
      </CButton>
    </CTooltip>
  );

  const shouldShowConsultByCdcButton =
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_UPLOADED_TO_SET ||
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_ERROR ||
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_RESEND_REQUESTED ||
    currentAutoInvoice.setResponseCode === "1001";

  const resendButton = (
    <CTooltip content="Reenviar">
      <CButton
        className="text-white"
        color="success"
        onClick={() => {
          onResendClick(currentAutoInvoice);
        }}
      >
        <i className="fa fa-refresh"></i>
      </CButton>
    </CTooltip>
  );

  const shouldShowResendButton =
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_ERROR ||
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_UPLOADED_TO_SET;

  return (
    <td className="text-center">
      <CButtonGroup>
        {shouldShowCancelButton ? cancelButton : <></>}
        {shouldShowEditAndDeleteButton ? (
          <>
            {editButton}
            {deleteButton}
          </>
        ) : (
          <></>
        )}
        {shouldShowConsultByCdcButton ? consultByCdcButton : <></>}
        {shouldShowResendButton ? resendButton : <></>}
      </CButtonGroup>
    </td>
  );
};

export default AutoInvoiceCrudActions;
