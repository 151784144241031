import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect, useRef, useState } from "react";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import Errors, {
  extractArrayErrors,
  getFieldErrors,
} from "../../models/errors";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import Loading from "../indicators/Loading";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import { CURRENCY_CHOICES, PYG } from "../../currency/available-currencies";
import CurrencyField from "../currencies/CurrencyField";
import AutoInvoiceItemForm from "./AutoInvoiceItemForm";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import PYGField from "../currencies/PYGField";
import { changeTZToUtc } from "../../utils/dates";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import {
  AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_CHOICES,
  AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI,
  AUTO_INVOICE_PROVIDER_TYPE_CHOICES,
  AUTO_INVOICE_PROVIDER_TYPE_NO_RUC,
  AUTO_INVOICE_STATUS_APPROVED_BY_SET,
  AUTO_INVOICE_STATUS_UPLOADED_TO_SET,
  AutoInvoice,
  AutoInvoiceItem,
  newAutoInvoice,
  newAutoInvoiceItem,
} from "../../models/auto-invoice";
import Department from "../../models/department";
import District from "../../models/district";
import City from "../../models/city";
import {
  getCityByCode,
  getDepartmentByCode,
  getDistrictByCode,
} from "../../api/locations";
import DepartmentSelect from "../location/DepartmentSelect";
import DistrictSelect from "../location/DistrictSelect";
import CitySelect from "../location/CitySelect";

const AutoInvoiceForm = () => {
  const name = "Agregar Autofactura Electrónica";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const autoInvoiceRef = useRef<AutoInvoice | null>(
    newAutoInvoice(taxPayer.id)
  );
  const [autoInvoiceDate, setAutoInvoiceDate] = useState<Date | undefined>(
    undefined
  );

  const [currency, setCurrency] = useState(PYG);
  const [pygExchangeRate, setPygExchangeRate] = useState<number | undefined>(
    undefined
  );
  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [globalDiscount, setGlobalDiscount] = useState<number | undefined>(
    undefined
  );

  /*const updateInvoiceRef = (invoice: Invoice) => {
    autoInvoiceRef.current = invoice;
  };*/

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [providerDepartment, setProviderDepartment] =
    useState<Department | null>(null);
  const [providerDistrict, setProviderDistrict] = useState<District | null>(
    null
  );
  const [providerCity, setProviderCity] = useState<City | null>(null);
  const [transactionDepartment, setTransactionDepartment] =
    useState<Department | null>(null);
  const [transactionDistrict, setTransactionDistrict] =
    useState<District | null>(null);
  const [transactionCity, setTransactionCity] = useState<City | null>(null);

  const [items, setItems] = useState<AutoInvoiceItem[]>([]);

  const history = useHistory();

  const getEditingAutoInvoiceId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingInvoiceId = urlParams.get("id");

    if (editingInvoiceId !== null) {
      if (!isNaN(parseInt(editingInvoiceId))) {
        return parseInt(editingInvoiceId);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.establishmentId = newEstablishment?.id;
    autoInvoiceRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.dispatchPointId = newDispatchPoint?.id;
    autoInvoiceRef.current.dispatchPointIdentifier =
      newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.stampId = newStamp?.id;
    autoInvoiceRef.current.stampIdentifier = newStamp?.identifier;
    autoInvoiceRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onAutoInvoiceDateChange = (value: string | moment.Moment) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const autoInvoiceDate = new Date((value as moment.Moment).toISOString());
    setAutoInvoiceDate(autoInvoiceDate);

    autoInvoiceRef.current.autoInvoiceDate = autoInvoiceDate.toISOString();
  };

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.currency = e.target.value;
    setCurrency(e.target.value);
  };

  const onProviderTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerType = e.target.value;
  };

  const onProviderDocumentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerDocumentType = e.target.value;
  };

  const onProviderDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerDocumentNumber = e.target.value;
  };

  const onProviderFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerFullName = e.target.value;
  };

  const onProviderAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerAddress = e.target.value;
  };

  const onProviderHouseNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }
    if (isNaN(parseInt(e.target.value))) {
      autoInvoiceRef.current.providerHouseNumber = undefined;
    } else {
      autoInvoiceRef.current.providerHouseNumber = parseInt(e.target.value);
    }
  };

  const onProviderDepartmentChange = (newDepartment: Department | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerDepartment = newDepartment?.code;
    autoInvoiceRef.current.providerDepartmentDescription =
      newDepartment?.description;
    autoInvoiceRef.current.providerDistrict = undefined;
    autoInvoiceRef.current.providerDistrictDescription = undefined;

    setProviderDepartment(newDepartment);
    setProviderDistrict(null);
    setProviderCity(null);
  };

  const onProviderDistrictChange = (newDistrict: District | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerDistrict = newDistrict?.code;
    autoInvoiceRef.current.providerDistrictDescription =
      newDistrict?.description;
    autoInvoiceRef.current.providerCity = undefined;
    autoInvoiceRef.current.providerCityDescription = undefined;

    setProviderDistrict(newDistrict);
    setProviderCity(null);
  };

  const onProviderCityChange = (newCity: City | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.providerCity = newCity?.code;
    autoInvoiceRef.current.providerCityDescription = newCity?.description;

    setProviderCity(newCity);
  };

  const onTransactionAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }
    autoInvoiceRef.current.transactionAddress = e.target.value;
  };

  const onTransactionHouseNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }
    if (isNaN(parseInt(e.target.value))) {
      autoInvoiceRef.current.transactionHouseNumber = undefined;
    } else {
      autoInvoiceRef.current.transactionHouseNumber = parseInt(e.target.value);
    }
  };

  const onTransactionDepartmentChange = (newDepartment: Department | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.transactionDepartment = newDepartment?.code;
    autoInvoiceRef.current.transactionDepartmentDescription =
      newDepartment?.description;
    autoInvoiceRef.current.transactionDistrict = undefined;
    autoInvoiceRef.current.transactionDistrictDescription = undefined;

    setTransactionDepartment(newDepartment);
    setTransactionDistrict(null);
    setTransactionCity(null);
  };

  const onTransactionDistrictChange = (newDistrict: District | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.transactionDistrict = newDistrict?.code;
    autoInvoiceRef.current.transactionDistrictDescription =
      newDistrict?.description;
    autoInvoiceRef.current.transactionCity = undefined;
    autoInvoiceRef.current.transactionCityDescription = undefined;

    setTransactionDistrict(newDistrict);
    setTransactionCity(null);
  };

  const onTransactionCityChange = (newCity: City | null) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.transactionCity = newCity?.code;
    autoInvoiceRef.current.transactionCityDescription = newCity?.description;

    setTransactionCity(newCity);
  };

  const onPygExchangeRateChange = (newExchangeRate?: number) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.pygExchangeRate = newExchangeRate;
    setPygExchangeRate(newExchangeRate);
  };

  const onApplyDiscountChange = (applyDiscount: boolean) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    setApplyDiscount(applyDiscount);
  };

  const onGlobalDiscountChange = (newGlobalDiscount?: number) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.globalDiscount = newGlobalDiscount;
  };

  const onCertificateNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.certificateNumber = e.target.value;
  };

  const onCertificateCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    autoInvoiceRef.current.certificateCode = e.target.value;
  };

  const onSave = async () => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<AutoInvoice>>;
    let successMessage = "Factura agregada exitósamente";

    const autoInvoice = autoInvoiceRef.current;
    autoInvoice.taxPayerRuc = taxPayer.ruc;
    autoInvoice.taxPayerSocialName = taxPayer.socialName;
    autoInvoice.taxPayerFantasyName = taxPayer.fantasyName;

    if (autoInvoice.currency === undefined) {
      autoInvoice.currency = PYG;
    }

    if (autoInvoice.providerType === undefined) {
      autoInvoice.providerType = AUTO_INVOICE_PROVIDER_TYPE_NO_RUC;
    }

    if (autoInvoice.providerDocumentType === undefined) {
      autoInvoice.providerDocumentType =
        AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_PARAGUAYAN_DNI;
    }

    if (autoInvoice.id === undefined) {
      requestPromise = createItem<AutoInvoice>(
        "/auto_invoices/create/",
        autoInvoice
      );
    } else {
      requestPromise = updateItem<AutoInvoice>(
        `/auto_invoices/${autoInvoice.id}/`,
        autoInvoice
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("autoInvoices"));
    }
  };

  const loadInitialInvoiceById = async (): Promise<AutoInvoice | null> => {
    const editingAutoInvoiceId = getEditingAutoInvoiceId();

    if (editingAutoInvoiceId === undefined) {
      return null;
    }

    let requestStatus = await getItem<AutoInvoice>(
      `/auto_invoices/${editingAutoInvoiceId}/`
    );

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.status === NOT_FOUND) {
        setLoadNotFound(true);
      } else {
        setLoadError(true);
      }

      return null;
    }

    if (requestStatus.data === undefined || requestStatus.data === null) {
      setLoadError(true);
      return null;
    }

    return requestStatus.data!;
  };

  const loadInitialData = async () => {
    setLoading(true);
    const autoInvoice = await loadInitialInvoiceById();

    if (autoInvoice === null) {
      setLoading(false);
      return;
    }

    if (
      autoInvoice.status === AUTO_INVOICE_STATUS_UPLOADED_TO_SET ||
      autoInvoice.status === AUTO_INVOICE_STATUS_APPROVED_BY_SET
    ) {
      setLoading(false);
      setLoadError(true);
    }

    const stampPromise = getStampById(autoInvoice.stampId);
    const establishmentPromise = getEstablishmentById(
      autoInvoice.establishmentId
    );
    const dispatchPointPromise = getDispatchPointById(
      autoInvoice.dispatchPointId
    );

    const providerDepartmentPromise = getDepartmentByCode(
      autoInvoice.providerDepartment
    );
    const providerDistrictPromise = getDistrictByCode(
      autoInvoice.providerDistrict
    );
    const providerCityPromise = getCityByCode(autoInvoice.providerCity);

    const transactionDepartmentPromise = getDepartmentByCode(
      autoInvoice.transactionDepartment
    );
    const transactionDistrictPromise = getDistrictByCode(
      autoInvoice.transactionDistrict
    );
    const transactionCityPromise = getCityByCode(autoInvoice.transactionCity);

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const providerDepartment = await providerDepartmentPromise;
    const providerDistrict = await providerDistrictPromise;
    const providerCity = await providerCityPromise;
    const transactionDepartment = await transactionDepartmentPromise;
    const transactionDistrict = await transactionDistrictPromise;
    const transactionCity = await transactionCityPromise;

    setProviderDepartment(providerDepartment);
    setProviderDistrict(providerDistrict);
    setProviderCity(providerCity);
    setTransactionDepartment(transactionDepartment);
    setTransactionDistrict(transactionDistrict);
    setTransactionCity(transactionCity);

    autoInvoiceRef.current = autoInvoice;

    setAutoInvoiceDate(new Date(autoInvoice.autoInvoiceDate!));

    setGlobalDiscount(autoInvoice.globalDiscount);
    if (autoInvoice.globalDiscount !== undefined) {
      setApplyDiscount(true);
    }
    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    onItemsChange(autoInvoice.items);

    setCurrency(autoInvoice.currency ? autoInvoice.currency : PYG);
    setPygExchangeRate(autoInvoice.pygExchangeRate);

    setLoading(false);
  };

  useEffect(() => {
    const newAutoInvoiceDate = changeTZToUtc(new Date());
    if (autoInvoiceRef.current !== null) {
      autoInvoiceRef.current.autoInvoiceDate = newAutoInvoiceDate.toISOString();
      setAutoInvoiceDate(newAutoInvoiceDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddItemClick = () => {
    //const newItems = [...items, newInvoiceItem()];
    //onItemsChange(newItems);
    if (autoInvoiceRef.current === null) {
      return;
    }

    const newItems = [...autoInvoiceRef.current.items, newAutoInvoiceItem()];
    onItemsChange(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onItemDelete = (index: number) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    const newItems = [...autoInvoiceRef.current.items];
    newItems.splice(index, 1);

    onItemsChange(newItems);
  };

  const onItemChange = (index: number, item: AutoInvoiceItem) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    const newItems = [...autoInvoiceRef.current.items];
    newItems[index] = item;
    onItemsChange(newItems);
  };

  const onItemsChange = (newItems: AutoInvoiceItem[]) => {
    if (autoInvoiceRef.current === null) {
      return;
    }

    let newTotalAmount: number | undefined = undefined;
    let newAmount10Percent: number | undefined = undefined;
    let newAmount5Percent: number | undefined = undefined;
    let newAmountExempt: number | undefined = undefined;
    let newIva10Percent: number | undefined = undefined;
    let newIva5Percent: number | undefined = undefined;

    for (let item of newItems) {
      if (item.unitAmount !== undefined && item.quantity !== undefined) {
        newTotalAmount = newTotalAmount
          ? newTotalAmount + item.unitAmount * item.quantity
          : item.unitAmount * item.quantity;
      }
    }

    setTotalAmount(newTotalAmount);

    autoInvoiceRef.current = {
      ...autoInvoiceRef.current,
      items: newItems,
      amount: newTotalAmount,
    };

    setItems(newItems);
  };

  const itemErrors = extractArrayErrors("items", errors);

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      <CCard>
        <CCardHeader>{name}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Timbrado:
                </CLabel>
              </CCol>
              <CCol>
                <StampSelect onChange={onStampChange} value={stamp} />
                <FieldErrors
                  errors={getFieldErrors("stampId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={getFieldErrors("stampIdentifier", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Establecimiento:
                </CLabel>
              </CCol>
              <CCol>
                <EstablishmentSelect
                  onChange={onEstablishmentChange}
                  value={establishment}
                />
                <FieldErrors
                  errors={getFieldErrors("establishmentId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={
                    getFieldErrors("establishmentCode", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Punto de Expedición:
                </CLabel>
              </CCol>
              <CCol>
                <DispatchPointSelect
                  onChange={onDispatchPointChange}
                  value={dispatchPoint}
                />
                <FieldErrors
                  errors={getFieldErrors("dispatchPointId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={
                    getFieldErrors(
                      "dispatchPointIdentifier",
                      errors
                    ) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Fecha de emisión:
                </CLabel>
              </CCol>
              <CCol>
                <Datetime
                  className="pl-0"
                  onChange={onAutoInvoiceDateChange}
                  value={autoInvoiceDate}
                  locale="es/PY"
                  utc
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                ></Datetime>
                <FieldErrors
                  errors={getFieldErrors("autoInvoiceDate", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo de proveedor:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current !== null
                      ? autoInvoiceRef.current.providerType
                      : PYG
                  }
                  onChange={onProviderTypeChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(AUTO_INVOICE_PROVIDER_TYPE_CHOICES.entries()).map(
                    (entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    }
                  )}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("providerType", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo de Documento
                  Proveedor:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current !== null
                      ? autoInvoiceRef.current.providerDocumentType
                      : PYG
                  }
                  onChange={onProviderDocumentTypeChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(
                    AUTO_INVOICE_PROVIDER_DOCUMENT_TYPE_CHOICES.entries()
                  ).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
                <FieldErrors
                  errors={
                    getFieldErrors("providerDocumentType", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nro. de Documento
                  Proveedor:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current !== null
                      ? autoInvoiceRef.current.providerDocumentNumber
                      : PYG
                  }
                  onChange={onProviderDocumentNumberChange}
                  placeholder="Nro. Documento"
                ></CInput>

                <FieldErrors
                  errors={
                    getFieldErrors("providerDocumentNumber", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nombre Proveedor:
                </CLabel>
              </CCol>
              <CCol md={10}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current !== null
                      ? autoInvoiceRef.current.providerFullName
                      : PYG
                  }
                  onChange={onProviderFullNameChange}
                  placeholder="Nombre Completo"
                ></CInput>

                <FieldErrors
                  errors={
                    getFieldErrors("providerFullName", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h5>
                <u>Dirección de Proveedor</u>
              </h5>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Dirección
                </CLabel>
              </CCol>
              <CCol md={6}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.providerAddress
                      : ""
                  }
                  onChange={onProviderAddressChange}
                  placeholder="Dirección"
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("providerAddress", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Número de casa:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="number"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.providerHouseNumber
                      : undefined
                  }
                  onChange={onProviderHouseNumberChange}
                  placeholder="Número de casa"
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("providerHouseNumber", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Departamento
                </CLabel>
              </CCol>
              <CCol md={2}>
                <DepartmentSelect
                  onChange={onProviderDepartmentChange}
                  value={providerDepartment}
                  placeholder="Departamento"
                />
                <FieldErrors
                  errors={
                    getFieldErrors("providerDepartment", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Distrito
                </CLabel>
              </CCol>
              <CCol md={2}>
                <DistrictSelect
                  onChange={onProviderDistrictChange}
                  value={providerDistrict}
                  placeholder="Distrito"
                  departmentValue={
                    providerDepartment !== null ? providerDepartment : undefined
                  }
                />
                <FieldErrors
                  errors={
                    getFieldErrors("providerDistrict", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Ciudad
                </CLabel>
              </CCol>
              <CCol>
                <CitySelect
                  onChange={onProviderCityChange}
                  placeholder="Ciudad"
                  value={providerCity}
                  district={
                    providerDistrict !== null ? providerDistrict : undefined
                  }
                  department={
                    providerDepartment !== null ? providerDepartment : undefined
                  }
                />
                <FieldErrors
                  errors={getFieldErrors("providerCity", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h5>
                <u>Dirección de Transacción</u>
              </h5>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Dirección
                </CLabel>
              </CCol>
              <CCol md={6}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.transactionAddress
                      : ""
                  }
                  onChange={onTransactionAddressChange}
                  placeholder="Dirección"
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("transactionAddress", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Número de casa:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="number"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.transactionHouseNumber
                      : undefined
                  }
                  onChange={onTransactionHouseNumberChange}
                  placeholder="Número de casa"
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("transactionHouseNumber", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Departamento
                </CLabel>
              </CCol>
              <CCol md={2}>
                <DepartmentSelect
                  onChange={onTransactionDepartmentChange}
                  value={transactionDepartment}
                  placeholder="Departamento"
                />
                <FieldErrors
                  errors={
                    getFieldErrors("transactionDepartment", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Distrito
                </CLabel>
              </CCol>
              <CCol md={2}>
                <DistrictSelect
                  onChange={onTransactionDistrictChange}
                  value={transactionDistrict}
                  placeholder="Distrito"
                  departmentValue={
                    transactionDepartment !== null
                      ? transactionDepartment
                      : undefined
                  }
                />
                <FieldErrors
                  errors={
                    getFieldErrors("transactionDistrict", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Ciudad
                </CLabel>
              </CCol>
              <CCol>
                <CitySelect
                  onChange={onTransactionCityChange}
                  placeholder="Ciudad"
                  value={transactionCity}
                  district={
                    transactionDistrict !== null
                      ? transactionDistrict
                      : undefined
                  }
                  department={
                    transactionDepartment !== null
                      ? transactionDepartment
                      : undefined
                  }
                />
                <FieldErrors
                  errors={getFieldErrors("transactionCity", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h5>
                <u>Datos Constancia de No Contribuyente (DNIT)</u>
              </h5>
            </CCol>
          </CRow>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Número de constancia No
                  Contribuyente:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.certificateNumber
                      : undefined
                  }
                  onChange={onCertificateNumberChange}
                  placeholder="Nro. Constancia no Contribuyente"
                ></CInput>
                <FieldErrors
                  errors={
                    getFieldErrors("certificateNumber", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Código de constancia No
                  Contribuyente:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CInput
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current
                      ? autoInvoiceRef.current.certificateCode
                      : undefined
                  }
                  onChange={onCertificateCodeChange}
                  placeholder="Código Constancia No Contribuyente"
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("certificateCode", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Moneda:</CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    autoInvoiceRef.current !== null
                      ? autoInvoiceRef.current.currency
                      : PYG
                  }
                  onChange={onCurrencyChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(CURRENCY_CHOICES.entries()).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("currency", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {currency !== PYG ? (
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Cambio:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <PYGField
                    placeholder="Total"
                    limit={1000000000}
                    onChange={onPygExchangeRateChange}
                    value={pygExchangeRate}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("pygExchangeRate", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          ) : (
            <></>
          )}

          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Total:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total"
                  limit={1000000000}
                  disabled={true}
                  value={totalAmount}
                />
                <FieldErrors
                  errors={getFieldErrors("amount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Aplicar Descuento:</CLabel>
              </CCol>
              <CCol md={2}>
                <CRow>
                  <AnimatedCheckbox
                    value={
                      autoInvoiceRef.current?.globalDiscount !== undefined &&
                      autoInvoiceRef.current?.globalDiscount !== null
                    }
                    onChange={onApplyDiscountChange}
                  ></AnimatedCheckbox>
                </CRow>

                <FieldErrors
                  errors={getFieldErrors("applyDiscount", errors) as string[]}
                ></FieldErrors>
              </CCol>
              {applyDiscount ? (
                <>
                  <CCol md={2}>
                    <CLabel>Descuento Global:</CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CurrencyField
                      currency={currency}
                      placeholder="Introduzca el monto"
                      onChange={onGlobalDiscountChange}
                      value={globalDiscount}
                      limit={1000000000}
                    />
                    <FieldErrors
                      errors={getFieldErrors("unitAmount", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                </>
              ) : (
                <></>
              )}
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h4>Ítems</h4>
            </CCol>
          </CRow>
          {items.map((item, ix) => (
            <CRow key={ix}>
              <CCol>
                <AutoInvoiceItemForm
                  currency={currency}
                  item={item}
                  onDelete={() => onItemDelete(ix)}
                  onChange={(item) => onItemChange(ix, item)}
                  errors={ix < itemErrors.length ? itemErrors[ix] : {}}
                />
              </CCol>
            </CRow>
          ))}
          <CRow>
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
              >
                Agregar Ítem
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" color="primary" onClick={onSave}>
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CCardFooter>
      </CCard>
    </>
  );
};

export default AutoInvoiceForm;
