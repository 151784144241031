import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Establishment, { newEstablishment } from "../../models/establishment";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import DepartmentSelect from "../location/DepartmentSelect";
import Department from "../../models/department";
import District from "../../models/district";
import City from "../../models/city";
import DistrictSelect from "../location/DistrictSelect";
import CitySelect from "../location/CitySelect";
import {
  getCityByCode,
  getDepartmentByCode,
  getDistrictByCode,
} from "../../api/locations";
import Loading from "../indicators/Loading";

interface EstablishmentFormProps {
  establishment?: Establishment;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const EstablishmentForm: React.FC<EstablishmentFormProps> = ({
  establishment,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );

  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);
  const [department, setDepartment] = useState<Department | null>(null);
  const [district, setDistrict] = useState<District | null>(null);
  const [city, setCity] = useState<City | null>(null);

  const establishmentRef = useRef<Establishment | null>(
    establishment ? establishment : null
  );

  const updateestablishmentRef = (establishment: Establishment) => {
    establishmentRef.current = establishment;
  };

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.code = e.target.value;
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.address = e.target.value;
  };

  const onHouseNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.houseNumber = e.target.value;
  };

  const onComplementaryAddress1Change = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.complementaryAddress1 = e.target.value;
  };

  const onComplementaryAddress2Change = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.complementaryAddress2 = e.target.value;
  };

  const onDepartmentChange = (newDepartment: Department | null) => {
    if (establishmentRef.current === null) {
      return;
    }

    establishmentRef.current.department = newDepartment?.code;
    establishmentRef.current.departmentDescription = newDepartment?.description;
    establishmentRef.current.district = undefined;
    establishmentRef.current.districtDescription = undefined;

    setDepartment(newDepartment);
    setDistrict(null);
    setCity(null);
  };

  const onDistrictChange = (newDistrict: District | null) => {
    if (establishmentRef.current === null) {
      return;
    }

    establishmentRef.current.district = newDistrict?.code;
    establishmentRef.current.districtDescription = newDistrict?.description;
    establishmentRef.current.city = undefined;
    establishmentRef.current.cityDescription = undefined;

    setDistrict(newDistrict);
    setCity(null);
  };

  const onCityChange = (newCity: City | null) => {
    if (establishmentRef.current === null) {
      return;
    }

    establishmentRef.current.city = newCity?.code;
    establishmentRef.current.cityDescription = newCity?.description;

    setCity(newCity);
  };

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.phoneNumber = e.target.value;
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.email = e.target.value;
  };

  const onDenominationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (establishmentRef.current === null) {
      return;
    }
    establishmentRef.current.denomination = e.target.value;
  };

  const onSave = async () => {
    const establishment = establishmentRef.current;

    if (establishment === null) {
      return;
    }

    setSubmitting(true);

    establishment.taxPayerId = taxPayerId;

    if (establishment.houseNumber === undefined) {
      establishment.houseNumber = "";
    }
    if (establishment.complementaryAddress1 === undefined) {
      establishment.complementaryAddress1 = "";
    }
    if (establishment.complementaryAddress2 === undefined) {
      establishment.complementaryAddress2 = "";
    }
    if (establishment.phoneNumber === undefined) {
      establishment.phoneNumber = "";
    }
    if (establishment.email === undefined) {
      establishment.email = "";
    }

    let requestPromise: Promise<ItemRequestStatus<Establishment>>;
    if (establishment.id === 0 || establishment.id === undefined) {
      requestPromise = createItem("/establishments/create/", establishment);
    } else {
      requestPromise = updateItem(
        `/establishments/${establishment.id}/`,
        establishment
      );
    }

    const establishmentStatus = await requestPromise;

    if (establishmentStatus.status !== SUCCESS) {
      if (establishmentStatus.errors !== undefined) {
        setErrors(establishmentStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (establishmentStatus.detail !== undefined) {
        message = establishmentStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateestablishmentRef(newEstablishment());
  };

  const loadEstablishment = async (establishment?: Establishment) => {
    setLoading(true);
    if (establishment !== undefined) {
      let departmentPromise: Promise<Department | null> | null = null;
      if (establishment.department !== undefined) {
        departmentPromise = getDepartmentByCode(establishment.department);
      }

      let districtPromise: Promise<District | null> | null = null;
      if (establishment.district !== undefined) {
        districtPromise = getDistrictByCode(establishment.district);
      }

      let cityPromise: Promise<City | null> | null = null;
      if (establishment.city !== undefined) {
        cityPromise = getCityByCode(establishment.city);
      }

      if (departmentPromise !== null) {
        const updatingDeparment = await departmentPromise;
        setDepartment(updatingDeparment);
      }

      if (districtPromise !== null) {
        const updatingDistrict = await districtPromise;
        setDistrict(updatingDistrict);
      }

      if (cityPromise !== null) {
        const updatingCity = await cityPromise;
        setCity(updatingCity);
      }
    }

    updateestablishmentRef(establishment ? establishment : newEstablishment());
    setLoading(false);
  };

  useEffect(() => {
    loadEstablishment(establishment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishment]);

  if (loading) {
    return <Loading height="30vh" />;
  }

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Código de Establecimiento</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                defaultValue={
                  establishmentRef.current ? establishmentRef.current.code : ""
                }
                onChange={onCodeChange}
                placeholder="Código de Establecimiento"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("code", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Dirección</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.address
                    : ""
                }
                onChange={onAddressChange}
                placeholder="Dirección"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("address", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nª de casa</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.houseNumber
                    : ""
                }
                onChange={onHouseNumberChange}
                placeholder="Nª de casa"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("houseNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Dirección complementaria 1</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.complementaryAddress1
                    : ""
                }
                onChange={onComplementaryAddress1Change}
                placeholder="Dirección complementaria 1"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("complementaryAddress1", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Dirección complementaria 2</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.complementaryAddress2
                    : ""
                }
                onChange={onComplementaryAddress2Change}
                placeholder="Dirección complementaria 2"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("complementaryAddress2", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Departamento</CLabel>
            </CCol>
            <CCol>
              <DepartmentSelect
                onChange={onDepartmentChange}
                value={department}
              />
              <FieldErrors
                errors={getFieldErrors("department", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Distrito</CLabel>
            </CCol>
            <CCol>
              <DistrictSelect
                onChange={onDistrictChange}
                value={district}
                departmentValue={department !== null ? department : undefined}
              />
              <FieldErrors
                errors={getFieldErrors("district", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Ciudad</CLabel>
            </CCol>
            <CCol>
              <CitySelect
                onChange={onCityChange}
                value={city}
                district={district !== null ? district : undefined}
                department={department !== null ? department : undefined}
              />
              <FieldErrors
                errors={getFieldErrors("city", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Teléfono</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.phoneNumber
                    : ""
                }
                onChange={onPhoneNumberChange}
                placeholder="Teléfono"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("phoneNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Email</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current ? establishmentRef.current.email : ""
                }
                onChange={onEmailChange}
                placeholder="Email"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Denominación</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  establishmentRef.current
                    ? establishmentRef.current.denomination
                    : ""
                }
                onChange={onDenominationChange}
                placeholder="Denominación"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("denomination", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default EstablishmentForm;
