import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import ClientDeleteModal from "./ClientDeleteModal";
import ClientUpdateModal from "./ClientUpdateModal";
import Client, {
  CLIENT_TYPE_PERSON,
  CLIENT_TYPE_PERSON_LABEL,
  CLIENT_TYPE_ENTITY,
  CLIENT_TYPE_ENTITY_LABEL,
} from "../../models/client";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const ITEMS_PER_PAGE = 10;

const ClientList = () => {
  const fields = [
    { key: "ruc", _classes: ["text-center"], label: "RUC o CI" },
    { key: "socialName", _classes: ["text-center"], label: "Razón social" },
    {
      key: "fantasyName",
      _classes: ["text-center"],
      label: "Nombre de fantasia",
    },
    { key: "type", _classes: ["text-center"], label: "Tipo" },
    { key: "phoneNumber", _classes: ["text-center"], label: "Teléfono" },
    { key: "email", _classes: ["text-center"], label: "Email" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [showClientEditModal, setShowClientEditModal] = useState(false);
  const [showClientDeleteModal, setShowClientDeleteModal] = useState(false);
  const [client, setClient] = useState<Client | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchClients = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());
    const clientsStatus = await getList<Client>(
      "/clients/",
      limit,
      offset,
      additionalParams
    );

    if (clientsStatus.status === SUCCESS) {
      if (clientsStatus.data !== undefined) {
        const count = clientsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setClients(clientsStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = clientsStatus.detail
        ? clientsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  /*const reset = () => {
    history.push(history.location.pathname);
  };*/

  const onAddClick = () => {
    setClient(undefined);
    setShowClientEditModal(true);
  };

  const onUpdateClick = (client: Client) => {
    setClient(client);
    setShowClientEditModal(true);
  };

  const onDeleteClick = (client: Client) => {
    setClient(client);
    setShowClientDeleteModal(true);
  };

  const onClientEditCancel = () => {
    setShowClientEditModal(false);
  };

  const onClientEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowClientEditModal(false);
    setClients([]);
    setLoading(true);
    //setTotalPages(1);
    //setCurrentPage(1);
    //reset();
    fetchClients();
  };

  const onClientDeleteCancel = () => {
    setShowClientDeleteModal(false);
  };

  const onClientDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowClientDeleteModal(false);
    setClients([]);
    setLoading(true);
    fetchClients();
  };

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("clients")) {
        return;
      }
      fetchClients();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Clientes</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={clients}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    ruc: (item: Client) => {
                      const ruc = item.ruc ? item.ruc : "-";
                      return <td className="text-center">{ruc}</td>;
                    },
                    socialName: (item: Client) => {
                      const socialName = item.socialName
                        ? item.socialName
                        : "-";
                      return <td className="text-center">{socialName}</td>;
                    },
                    fantasyName: (item: Client) => {
                      const fantasyName = item.fantasyName
                        ? item.fantasyName
                        : "-";
                      return <td className="text-center">{fantasyName}</td>;
                    },
                    phoneNumber: (item: Client) => {
                      const phoneNumber = item.phoneNumber
                        ? item.phoneNumber
                        : "-";
                      return <td className="text-center">{phoneNumber}</td>;
                    },
                    email: (item: Client) => {
                      const email = item.email ? item.email : "-";
                      return <td className="text-center">{email}</td>;
                    },
                    type: (item: Client) => {
                      let typeStr = "-";
                      if (item.type === CLIENT_TYPE_PERSON) {
                        typeStr = CLIENT_TYPE_PERSON_LABEL;
                      } else if (item.type === CLIENT_TYPE_ENTITY) {
                        typeStr = CLIENT_TYPE_ENTITY_LABEL;
                      }
                      return <td className="text-center">{typeStr}</td>;
                    },
                    actions: (item: Client) => {
                      const editButton = (
                        <CButton
                          className="text-white"
                          color="warning"
                          onClick={() => {
                            onUpdateClick(item);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </CButton>
                      );
                      const deleteButton = (
                        <CButton
                          className="text-white"
                          color="danger"
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </CButton>
                      );

                      return (
                        <td className="text-center">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ClientUpdateModal
        show={showClientEditModal}
        onCancel={onClientEditCancel}
        onSuccess={onClientEditSuccess}
        client={client}
      />
      <ClientDeleteModal
        show={showClientDeleteModal}
        onCancel={onClientDeleteCancel}
        onSuccess={onClientDeleteSuccess}
        client={client}
      />
    </>
  );
};

export default ClientList;
