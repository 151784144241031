export interface RemissionNoteItem {
  id?: number;
  remissionNoteId?: number;
  description?: string;
  obs?: string;
  measureUnit?: string;
  quantity?: number;
}

export interface RemissionNote {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  uniqueCode?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  clientId?: number;
  clientCode?: string;
  clientIsTaxPayer?: boolean;
  clientRuc?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  clientType?: string;
  clientCountryCode?: string;
  clientHasAddress?: boolean;
  clientAddress?: string;
  clientHouseNumber?: number;
  clientDepartment?: number;
  clientDepartmentDescription?: string;
  clientDistrict?: number;
  clientDistrictDescription?: string;
  clientCity?: number;
  clientCityDescription?: string;
  clientPhoneNumber?: string;
  clientEmail?: string;
  remissionNoteDate?: string;
  remissionNoteNumber?: string;
  description?: string;
  obs?: string;
  reasonCode?: number;
  responsibleCode?: number;
  estimatedKilometers?: number;
  transportTypeCode?: number;
  transportEstimatedStartDate?: string;
  transportEstimatedEndDate?: string;
  transportModeCode?: number;
  transportResponsibleCode?: number;
  transportDepartureAddress?: string;
  transportDepartureHouseNumber?: number;
  transportDepartureDepartment?: number;
  transportDepartureDepartmentDescription?: string;
  transportDepartureDistrict?: number;
  transportDepartureDistrictDescription?: string;
  transportDepartureCity?: number;
  transportDepartureCityDescription?: string;
  transportArrivalAddress?: string;
  transportArrivalHouseNumber?: number;
  transportArrivalDepartment?: number;
  transportArrivalDepartmentDescription?: string;
  transportArrivalDistrict?: number;
  transportArrivalDistrictDescription?: string;
  transportArrivalCity?: number;
  transportArrivalCityDescription?: string;
  transportVehicleType?: string;
  transportVehicleBrand?: string;
  transportVehicleDocumentTypeCode?: number;
  transportVehicleDocumentNumber?: string;
  transportVehicleRegistrationNumber?: string;
  carrierIsTaxPayer?: boolean;
  carrierRuc?: string;
  carrierSocialName?: string;
  carrierAddress?: string;
  driverDocumentNumber?: string;
  driverName?: string;
  driverAddress?: string;

  hasAssociatedDocument?: boolean;
  associatedDocumentType?: string;
  invoiceId?: number;
  associatedDocumentCdc?: string;
  associatedDocumentPrintedType?: string;
  associatedDocumentStampIdentifier?: string;
  associatedDocumentEstablishmentCode?: string;
  associatedDocumentDispatchPointCode?: string;
  associatedDocumentNumber?: string;
  associatedDocumentDate?: string;

  invoiceNumber?: string;
  cdc?: string;

  xmlPath?: string;
  pdfPath?: string;

  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;

  items: RemissionNoteItem[];

  createdAt?: string;
  updatedAt?: string;
}

export const REMISSION_NOTE_STATUS_PENDING = "pending";
export const REMISSION_NOTE_STATUS_PENDING_LABEL = "Pendiente";
export const REMISSION_NOTE_STATUS_ERROR = "error";
export const REMISSION_NOTE_STATUS_ERROR_LABEL = "Error";
export const REMISSION_NOTE_STATUS_GENERATED = "generated";
export const REMISSION_NOTE_STATUS_GENERATED_LABEL = "Generado";
export const REMISSION_NOTE_STATUS_UPLOADED_TO_SET = "uploaded_to_set";
export const REMISSION_NOTE_STATUS_UPLOADED_TO_SET_LABEL = "Subido";
export const REMISSION_NOTE_STATUS_APPROVED_BY_SET = "approved_by_set";
export const REMISSION_NOTE_STATUS_APPROVED_BY_SET_LABEL = "Aprobado";
export const REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR = "set_unknown_error";
export const REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL = "Desconocido";
export const REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED =
  "cancellation_requested";
export const REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const REMISSION_NOTE_STATUS_CANCELLED = "cancelled";
export const REMISSION_NOTE_STATUS_CANCELLED_LABEL = "Anulado";
export const REMISSION_NOTE_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const REMISSION_NOTE_STATUS_CANCELLATION_FAILED_LABEL =
  "Anulación Fallida";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_REQUESTED =
  "disagreement_requested";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL =
  "Disconformidad Solicitada";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_ACCEPTED =
  "disagreement_accepted";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL =
  "Disconformidad Aceptada";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_FAILED = "disagreement_failed";
export const REMISSION_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL =
  "Disconformidad Fallida";

export const REMISSION_NOTE_STATUS_CHOICES: Map<string, string> = new Map([
  [REMISSION_NOTE_STATUS_PENDING, REMISSION_NOTE_STATUS_PENDING_LABEL],
  [REMISSION_NOTE_STATUS_ERROR, REMISSION_NOTE_STATUS_ERROR_LABEL],
  [REMISSION_NOTE_STATUS_GENERATED, REMISSION_NOTE_STATUS_GENERATED_LABEL],
  [
    REMISSION_NOTE_STATUS_UPLOADED_TO_SET,
    REMISSION_NOTE_STATUS_UPLOADED_TO_SET_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_APPROVED_BY_SET,
    REMISSION_NOTE_STATUS_APPROVED_BY_SET_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR,
    REMISSION_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED,
    REMISSION_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [REMISSION_NOTE_STATUS_CANCELLED, REMISSION_NOTE_STATUS_CANCELLED_LABEL],
  [
    REMISSION_NOTE_STATUS_CANCELLATION_FAILED,
    REMISSION_NOTE_STATUS_CANCELLATION_FAILED_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_DISAGREEMENT_REQUESTED,
    REMISSION_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_DISAGREEMENT_ACCEPTED,
    REMISSION_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL,
  ],
  [
    REMISSION_NOTE_STATUS_DISAGREEMENT_FAILED,
    REMISSION_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL,
  ],
]);

export const REMISSION_NOTE_REASON_CHOICES: Map<number, string> = new Map([
  [2, "Translado por Consignación"],
]);

export const REMISSION_NOTE_RESPONSIBLE_CHOICES: Map<number, string> = new Map([
  [2, "Poseedor de la factura y bienes"],
]);

export const newRemissionNote = (taxPayerId?: number): RemissionNote => {
  return {
    taxPayerId: taxPayerId,
    items: [],
  };
};

export const newRemissionNoteItem = (): RemissionNoteItem => {
  return {};
};

export const formatRemissionNoteNumber = (
  remissionNote: RemissionNote
): string => {
  return `${remissionNote.establishmentCode}-${remissionNote.dispatchPointIdentifier}-${remissionNote.remissionNoteNumber}`;
};
