import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  //CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";

//import CIcon from '@coreui/icons-react';

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );

  const isConfigurationNavPage = () => {
    return (
      window.location.pathname === getRoutePath("regimeTypes") ||
      window.location.pathname === getRoutePath("economicActivities") ||
      window.location.pathname === getRoutePath("taxPayer") ||
      window.location.pathname === getRoutePath("stamps") ||
      window.location.pathname === getRoutePath("establishments") ||
      window.location.pathname === getRoutePath("dispatchPoints")
    );
  };

  const [showConfigurationNav, setShowConfigurationNav] = useState(
    isConfigurationNavPage()
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen((_) => {
      setShowConfigurationNav(isConfigurationNavPage());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        {/*<CSidebarNavItem
          name={getRouteName("users")}
          to={getRoutePath("users")}
          icon={"cil-people"}
        ></CSidebarNavItem>*/}
        {/*<CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />*/}
        <CSidebarNavItem
          name={getRouteName("invoices")}
          to={getRoutePath("invoices")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("creditNotes")}
          to={getRoutePath("creditNotes")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("debitNotes")}
          to={getRoutePath("debitNotes")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("remissionNotes")}
          to={getRoutePath("remissionNotes")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("autoInvoices")}
          to={getRoutePath("autoInvoices")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("receipts")}
          to={getRoutePath("receipts")}
          icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("disablements")}
          to={getRoutePath("disablements")}
          icon={<i className="c-sidebar-nav-icon fa fa-hashtag"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("clients")}
          to={getRoutePath("clients")}
          icon={"cil-people"}
        ></CSidebarNavItem>
        <CSidebarNavDropdown
          name="Configuración"
          icon={<i className="c-sidebar-nav-icon fa fa-gear"></i>}
          show={showConfigurationNav}
        >
          <CSidebarNavItem
            name={getRouteName("regimeTypes")}
            to={getRoutePath("regimeTypes")}
            icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
          ></CSidebarNavItem>
          <CSidebarNavItem
            name={getRouteName("economicActivities")}
            to={getRoutePath("economicActivities")}
            icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
          ></CSidebarNavItem>
          <CSidebarNavItem
            name={getRouteName("stamps")}
            to={getRoutePath("stamps")}
            icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
          ></CSidebarNavItem>
          <CSidebarNavItem
            name={getRouteName("establishments")}
            to={getRoutePath("establishments")}
            icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
          ></CSidebarNavItem>
          <CSidebarNavItem
            name={getRouteName("dispatchPoints")}
            to={getRoutePath("dispatchPoints")}
            icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
          ></CSidebarNavItem>
          <CSidebarNavItem
            name={getRouteName("taxPayer")}
            to={getRoutePath("taxPayer")}
            icon={"company-icon"}
          ></CSidebarNavItem>
        </CSidebarNavDropdown>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
